var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"outer"},[_c('div',{staticClass:"IU"},[_c('span',{staticClass:"gloss-src src-num"},[_vm._v(_vm._s(_vm.glossNum))]),('audio_url' in _vm.gloss)?[_c('button',{staticClass:"iu-audio",attrs:{"title":"IU發音"},on:{"click":function($event){return _vm.playAudio(_vm.gloss.audio_url)}}},[_c('v-icon',{attrs:{"small":"","color":"blue lighten-2"}},[_vm._v("mdi-volume-high")])],1)]:(('video' in _vm.gloss.meta) & (_vm.gloss.meta.video != 'None'))?[((_vm.gloss.iu_a_span[0] != null) & (_vm.gloss.iu_a_span[1] != null))?_c('button',{staticClass:"iu-audio",attrs:{"title":"IU發音"},on:{"click":function($event){_vm.playAudio(
            _vm.get_audio_url_by_split_time(
              _vm.gloss.iu_a_span[0],
              _vm.gloss.iu_a_span[1],
              _vm.gloss.meta.video
            )
          )}}},[_c('v-icon',{attrs:{"small":"","color":"blue lighten-2"}},[_vm._v("mdi-volume-high")])],1):_vm._e()]:_vm._e(),_c('div',{staticClass:"example gloss--glossed"},[(_vm.gloss.ori.length > 0)?_c('p',{staticClass:"gloss__line--original gloss__line gloss__line--0"},[_c('span',[_vm._v(_vm._s(this.gloss.ori.join(" ")))])]):_vm._e(),_c('div',{staticClass:"gloss__words"},_vm._l((this.gloss.gloss),function(tup,idx){return _c('div',{key:idx + Math.random(),staticClass:"gloss__word"},[_c('p',{staticClass:"gloss__line gloss__line--1"},[_c('span',[_vm._v(_vm._s(tup[0] == '_' ? '' : tup[0]))])]),_c('p',{staticClass:"gloss__line gloss__line--2"},[_c('span',[_vm._v(_vm._s(tup[1] == '_' ? '' : tup[1]))])]),_c('p',{staticClass:"gloss__line gloss__line--3"},[_c('span',[_vm._v(_vm._s(tup[2] == '_' ? '' : tup[2]))])])])}),0),_vm._l((this.gloss.free),function(line,i){return _c('p',{key:i + Math.random(),class:("gloss__line--free gloss__line gloss__line--" + (i + 4))},[_c('span',[_vm._v(_vm._s(line))])])})],2)],2),(
      _vm.gloss.s_end & ('video' in _vm.gloss.meta) & (_vm.gloss.meta.video != 'None')
    )?_c('div',{staticClass:"full-sent-audio"},[((_vm.gloss.s_a_span[0] != null) & (_vm.gloss.s_a_span[1] != null))?_c('button',{staticClass:"sent-audio",attrs:{"title":"例句發音"},on:{"click":function($event){_vm.playAudio(
          _vm.get_audio_url_by_split_time(
            _vm.gloss.s_a_span[0],
            _vm.gloss.s_a_span[1],
            _vm.gloss.meta.video
          )
        )}}},[_c('v-icon',{attrs:{"dense":"","color":"white"}},[_vm._v("mdi-volume-high "+_vm._s(_vm.lastSentEndNum))]),(_vm.lastSentEndNum2 + 1 != _vm.glossNum)?_c('span',[_vm._v(" "+_vm._s(_vm.lastSentEndNum2 + 1)+" - "+_vm._s(_vm.glossNum)+" ")]):_c('span',[_vm._v(_vm._s(_vm.glossNum))])],1):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }